import OrderDetails from "./order-details";
import AccountDetails from "./account-details";
import { CheckoutProduct, Product } from '../models/product';
import React, { useState, useEffect } from "react";
import PaymentInformation from "../payments/payment-information";
import { Accordion, AccordionItem, AccordionItemHeader, Iconsvg, Smokebreak,Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ButtonGroup,
    ButtonGroupItem,
	ButtonField, } from '@ldi/components-react16';
import User from '../models/user';
import checkoutService from '../services/checkoutService';
import { useNavigate, useParams } from "react-router-dom";
import SecurePaymentIcon from  '../assets/images/lock.svg'

const Checkout = (props: any) => {

	let { trackType } = useParams();
	let { trackPage } = useParams();

	const navigate = useNavigate();

	const [openMobileOrderSmokeBreak, setOpenMobileOrderSmokeBreak] = useState(false);
	const [checkoutProduct, setCheckoutProduct] = useState<CheckoutProduct>({} as CheckoutProduct);
	const [isLoading, setIsLoading] = useState(true);
	const [screenSize, setScreenSize] = useState('desktop')
	const [user, setUser] = useState<User>({} as User);

	interface CheckoutStepsCompleted { id: string, isOpen: boolean, isDisabled: boolean, title: string }

	const [accountAccordian, setAccountAccordian] = useState<CheckoutStepsCompleted>({ id: "account", isOpen: true, isDisabled: false, title: "Account Details", })
	const [paymentAccordian, setPaymentAccordian] = useState<CheckoutStepsCompleted>({ id: "payment", isOpen: false, isDisabled: true, title: "Payment Information" })
	
	const [width, setWidth] = useState(0);
	const [modalHeader, setModalHeader] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    
	useEffect(() => {
		const fetchProductDetails = async () => {
			var data = await checkoutService.ProductDetails(trackType || '', trackPage || '');
			if (data) {
				const product = data.filter((a: Product) => !a.isAddOn)[0];
				const addOn = data.filter((a: Product) => a.isAddOn);
				setCheckoutProduct({
					...checkoutProduct,
					product: product,
					addOnProducts: addOn,
					orderGrandTotal: product.price,
					priceWithoutTax: product.price,
					totalTax: 0,
					showTotalPriceCalculation: false
				});
			}
			else {
				navigate('/not-found')
				setIsLoading(false);
			}
		}
		fetchProductDetails();
	}, [trackType, trackPage]) //eslint-disable-line

	
	useEffect(() => {
		const handleResize = () => {
			const { innerWidth: width } = window;
        	setWidth(width);

			if (window.matchMedia("(max-width: 600px").matches) {
				setScreenSize('mobile');
			} else if (window.matchMedia("(max-width: 1024px)").matches) {
				setScreenSize('tablet');
			} else {
				setScreenSize('desktop');
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	let bannerURL = Object.keys(checkoutProduct).length && Object.keys(checkoutProduct.product).length > 0 ? screenSize === "mobile" ? checkoutProduct.product.mobileBannerImageUrl : screenSize === "tablet" ? checkoutProduct.product.tabletBannerImageUrl : checkoutProduct.product?.bannerImageUrl : checkoutProduct.product?.bannerImageUrl;

	useEffect(() => {
		if(user.countryId === 37)
			handleTaxUpdateChange(user.state, true);
		else
			handleTaxUpdateChange(user.zipCode);

	}, [checkoutProduct.priceWithoutTax])	//eslint-disable-line

	const handlePayment = (token: string, paymentType: number, cardTypeAndMaskedPan:any,  newUser: User, product:CheckoutProduct, applePayStatus:any) => {
		if(product)
			product.showTotalPriceCalculation = true;
		setIsLoading(true);

		let submitOrderPayload = {
			authToken: paymentType === 1 ? JSON.stringify(token) : token,
			orderGrandTotal: product?.orderGrandTotal ?? checkoutProduct.orderGrandTotal,
			saleTax: product?.totalTax ?? checkoutProduct.totalTax,
			shippingTax: 0,
			totalTax: product?.totalTax ?? checkoutProduct.totalTax,
			hasTax: true,
			shippingPrice: 0,
			discount: isNaN(product?.membershipDiscount) ? (isNaN(checkoutProduct.membershipDiscount) ? '0' : `${checkoutProduct.membershipDiscount}`) : `${product?.membershipDiscount}`,
			priceWithoutTax: product?.priceWithoutTax ?? checkoutProduct.priceWithoutTax,
			meetingId: checkoutProduct.product.meetingID,
			ssoMasterCustomerId: user.ssoCustomerId,
			societyAcronym: checkoutProduct.product.societyAcronym,
			isSSOMeeting: checkoutProduct.product.isSSO,
			paymentType: paymentType,
			customer: {
				profDesignationId: user.professionalDesignation ?? '01',
				firstName: newUser?.firstName?? user.firstName ,
				lastName: newUser?.lastName ?? user.lastName,
				zipCode: newUser?.zipCode ?? user.zipCode ?? "",
				stateName: newUser?.state ?? user.state ??  '',
				emailAddress: user.email,
				country: newUser?.country ?? user.country ,
				countryId: newUser?.countryId ? `${newUser?.countryId}` : `${user.countryId}`,
				thinkCustomerID: user.thinkCustomerId,
				ssoCustomerId: user.ssoCustomerId,
				isNewUser: user.isNewUser
			},
			products: [
				{
					sku: `${checkoutProduct.product.productID}`,
					name: checkoutProduct.product.productTitle,
					isAddOn: checkoutProduct.product.isAddOn,
					unitPrice: checkoutProduct.product.newPrice || checkoutProduct.product.price,
					sourceCodeId: checkoutProduct.product.newSourceCodeId || checkoutProduct.product.sourceCodeID,
					trackPage: checkoutProduct.product.trackPage,
					trackType: checkoutProduct.product.trackType,
					originalPrice: checkoutProduct.product.price
				}
			],
			cardDetails:{
				maskedPan: cardTypeAndMaskedPan ? cardTypeAndMaskedPan.maskedPan : "",
                cardType: cardTypeAndMaskedPan ? cardTypeAndMaskedPan.cardType : ""
			},
			transactionId: "",
			orderNumber: "",
			thinkCustomerId: ""
		}

		checkoutProduct.addOnProducts.filter((a: Product) => a.isPurchased).map((p: Product) => submitOrderPayload.products.push({
			sku: `${p.productID}`,
			name: p.productTitle,
			isAddOn: p.isAddOn,
			unitPrice: p.newPrice || p.price,
			sourceCodeId: p.newSourceCodeId || p.sourceCodeID,
			trackPage: p.trackPage,
			trackType: p.trackType,
			originalPrice: p.price
		}))

		checkoutService.CheckoutSubmitOrder(submitOrderPayload)
			.then((response: any) => {
				if (response.data.isTransactionSuccessful) {
					if(paymentType === 3){
						const status = "success";
						applePayStatus.complete(status);
					}
					
					const paymentResponse = {
						transactionId: response.data.transactionId,
						orderNumber: response.data.orderNumber,
						thinkCustomerId: response.data.thinkCustomerId,
					}
					sessionStorage.setItem("orderDetails", JSON.stringify(paymentResponse))
					sessionStorage.setItem("checkoutProduct", JSON.stringify(product ?? checkoutProduct))

					if (user.isNewUser && !user.isSSOUser && !checkoutProduct.product.isSSO) {
						registerUser(submitOrderPayload, paymentResponse.thinkCustomerId);
					}
					navigate("/checkout/thankyou")
				}
				else {
					alert(response.data.message);
				}
				setIsLoading(false)
			})
	}

	const registerUser = (submitOrderPayload: any, thinkCustomerId: any) => {
		const payload = {
			email: submitOrderPayload.customer.emailAddress,
			password: user.password,
			username: submitOrderPayload.customer.emailAddress,
			ssoCustomerId: submitOrderPayload.customer.ssoCustomerId,
			thinkCustomerId: thinkCustomerId,
			firstName: submitOrderPayload.customer.firstName,
			lastName: submitOrderPayload.customer.lastName
		}
		checkoutService.RegisterCheckoutUser(payload);
	}

	const handleUserAndProductUpdate = (user:User, product:CheckoutProduct) =>{
		product.showTotalPriceCalculation = true;
		setCheckoutProduct(product);
		setUser(user);
	}

	const handleAddOnProductPurchase = (productId: number, selection: boolean) => {
		let purchasedProduct: Product = checkoutProduct.addOnProducts.filter((a: Product) => a.productID === productId)[0];
		purchasedProduct.isPurchased = selection;

		const priceWithoutTax = (checkoutProduct.product.newPrice || checkoutProduct.product.price) + checkoutProduct.addOnProducts.filter(a => a.isPurchased).reduce((sum: number, a: Product,) => sum += a.newPrice || a.price, 0)
		const orderGrandTotal = (checkoutProduct.product.newPrice || checkoutProduct.product.price) + checkoutProduct.addOnProducts.filter(a => a.isPurchased).reduce((sum: number, a: Product,) => sum += a.newPrice || a.price, 0)

		const memberDiscount = checkoutProduct.product.discount + checkoutProduct.addOnProducts.filter(a => a.isPurchased).reduce((sum: number, a: Product,) => sum += a.discount, 0)

		setCheckoutProduct({
			...checkoutProduct,
			addOnProducts: [...checkoutProduct.addOnProducts.filter((a: Product) => a.productID !== productId), purchasedProduct],
			priceWithoutTax: priceWithoutTax,
			orderGrandTotal: orderGrandTotal,
			membershipDiscount: memberDiscount,
		})

	}

	const handleMemberCheckoutDiscount = (discounts: any) => {
		let product = checkoutProduct.product;
		let addOnProduct = checkoutProduct.addOnProducts;
		let priceWithoutTax: number = 0;
		let orderGrandTotal: number = 0;

		if (discounts && discounts.length) {
			const discountedMainProduct = discounts.filter((p: any) => p.sellingProductId === product.productID)[0];
			product.newPrice = discountedMainProduct.newPrice;
			product.newPriceText = discountedMainProduct.newPriceText;
			product.newSourceCodeId = discountedMainProduct.newSourceCodeID;
			product.discount = discountedMainProduct.discount;
			product.discountText = discountedMainProduct.discountText;

			addOnProduct.forEach((a: Product) => {
				const discountedProduct = discounts.filter((p: any) => p.sellingProductId === a.productID)[0];
				a.newPrice = discountedProduct.newPrice;
				a.newPriceText = discountedProduct.newPriceText;
				a.sourceCodeID = discountedProduct.newSourceCodeID;
				a.discount = discountedProduct.discount;
				a.discountText = discountedProduct.discountText;
			})
		}
		else {
			product.newPrice = 0;
			product.newPriceText = "";
			product.newSourceCodeId = 0;
			product.discount = 0;
			product.discountText = "";
			addOnProduct.forEach((a: Product) => {
				a.newPrice = 0;
				a.newPriceText = "";
				a.newSourceCodeId = 0;
				a.discount = 0;
				a.discountText = "";
			})
		}

		const membershipDiscount = checkoutProduct.product.discount + checkoutProduct.addOnProducts.filter(a => a.isPurchased).reduce((sum: number, a: Product,) => sum += a.discount, 0)
		orderGrandTotal = (product.newPrice || product.price) + addOnProduct.filter(a => a.isPurchased).reduce((sum: number, a: Product,) => sum += a.newPrice || a.price, 0)
		priceWithoutTax = (product.newPrice || product.price) + addOnProduct.filter(a => a.isPurchased).reduce((sum: number, a: Product,) => sum += a.newPrice || a.price, 0)

		setCheckoutProduct({
			...checkoutProduct,
			product: product,
			addOnProducts: addOnProduct,
			orderGrandTotal: orderGrandTotal,
			priceWithoutTax: priceWithoutTax,
			membershipDiscount: membershipDiscount,
			showTotalPriceCalculation: false
		})
	}

	const handleCheckoutStepsClose = (item: string) => {
		if (item === "account") {
			setAccountAccordian({ ...accountAccordian, isOpen: !accountAccordian.isOpen });
			setPaymentAccordian({ ...paymentAccordian, isOpen: false, isDisabled: true });
			setCheckoutProduct({ ...checkoutProduct, orderGrandTotal: checkoutProduct.priceWithoutTax, totalTax: 0, showTotalPriceCalculation: false })
		}
		else {
			setPaymentAccordian({ ...paymentAccordian, isOpen: !paymentAccordian.isOpen, isDisabled: false });
			setAccountAccordian({ ...accountAccordian, isOpen: false });
			handleTaxUpdateChange(user.zipCode);
		}

	}

	const handleCompleteAccountSetup = (email: string) => {
		setAccountAccordian({ ...accountAccordian, isOpen: false, title: `Account Details: (${email})` })
		setPaymentAccordian({ ...paymentAccordian, isOpen: true, isDisabled: false });
		handleTaxUpdateChange(user.zipCode);
	}

	const handleTaxUpdateChange = async (zipCodeorStateCode: string, isTaxByStateCode: boolean = false) => {
		if (zipCodeorStateCode) {
			setIsLoading(true);
			// const fetchAppliedTax = async () => {
				let data: any = {};
				if (!isTaxByStateCode)
					data = await checkoutService.AppliedTax(user.countryId, user.stateId, user.state, zipCodeorStateCode, checkoutProduct.priceWithoutTax);
				else
					data = await checkoutService.AppliedTaxByCountryCode(user.country, user.countryId, zipCodeorStateCode, '', checkoutProduct.priceWithoutTax);

				setIsLoading(false);
				if(data && data.isTaxValid){
					setCheckoutProduct({ ...checkoutProduct, orderGrandTotal: data.totalAmount.toFixed(2), totalTax: data.salesTaxAmount.toFixed(2), showTotalPriceCalculation: true })
					return true;
				}
				else {
					return false;
				}
			// }
			// fetchAppliedTax();
		}
	}

	const resetTaxForProduct = () => {
		setCheckoutProduct({ ...checkoutProduct, orderGrandTotal: checkoutProduct.priceWithoutTax, totalTax: 0, showTotalPriceCalculation: true })
	}


	const handleZipCodeRemoval = () => {
		setCheckoutProduct({ ...checkoutProduct, orderGrandTotal: checkoutProduct.priceWithoutTax, totalTax: 0, showTotalPriceCalculation: true })
	}

	const handleUserRequest = () => {
        setShowModal(false)
    }

	return (
		<>
		 <Modal
                        isOpen={showModal}
                        srLabelledBy={'modal-id-001'}
                        srDescribedBy={'modal-id-002'}
                        onUserRequest={() => handleUserRequest()}>
                        <ModalHeader
                            titleId={'modal-id-001'}
                            descriptionId={'modal-id-002'}
                            isCloseButtonVisible={false}
                            onUserRequest={() => handleUserRequest()}>
                            {modalHeader}
                        </ModalHeader>
                        <ModalBody>{modalMessage}</ModalBody>
                        <ModalFooter>
                            <ButtonGroup mode="static">
                                <ButtonGroupItem slot="buttonGroupItem">
                                    <ButtonField>
                                        <button
                                            type="button"
                                            id="defaultClose"
                                            onClick={() => handleUserRequest()}>
                                            Close
                                        </button>
                                    </ButtonField>
                                </ButtonGroupItem>
                            </ButtonGroup>
                        </ModalFooter>
                    </Modal>
			{!Object.keys(checkoutProduct).length && isLoading ?
				<div className="loading-page">
					<div className="waiting-overlay" id="overlay-loader" >
						<div className="waiting-spinner"></div>
					</div>
				</div>

				:
				<>
					{isLoading &&
						<div className="waiting-overlay" id="overlay-loader" >
							<div className="waiting-spinner"></div>
						</div>
					}

					<div className="wk-grid-page-container p-0 w-85vw tab-height white-bg padding-bottom-2">
						<div>
							<img src={bannerURL} className="banner-image" alt="society-banner-image" width={width > 1440 ? 1440 : width} style={{ objectFit: 'contain' }}></img>
						</div>
						<div className="wk-grid custom-gap custom-height">
							<>
								<div className="wk-column-8 wk-column-12-xs border-right">
									<div className="wk-grid-page-container p-0">
										<div className="display-block d-flex-mb border-bottom">
											<div className="wk-column-8-xs  wk-column-12">
												<h2 className="fw-500">Secure Checkout <img src={SecurePaymentIcon} alt="secure-payment-icon"></img></h2>
											</div>
											<div className="wk-column-4-xs display-flex flex-items-center flex-justify-end">
												<span className="d-none-lg link-text" onClick={() => setOpenMobileOrderSmokeBreak(!openMobileOrderSmokeBreak)}>Order details</span>
											</div>
										</div>
									</div>
									<Accordion>
										<AccordionItem controlMode={'controlled'} isOpen={accountAccordian.isOpen} onUserRequest={() => handleCheckoutStepsClose('account')}>
											<h2 id="expandedByDefault" slot="accordionItemHeader">
												<AccordionItemHeader>{accountAccordian.title} &nbsp;
													{user.accountSetupComplete && <Iconsvg name='check-circle' color='green'></Iconsvg>}
												</AccordionItemHeader>
											</h2>
											<div>
												<div slot="accordionItemBody">
													<AccountDetails config={props.config} user={user} setUser={setUser} isLoading={isLoading} setLoading={setIsLoading} checkoutProduct={checkoutProduct} handleMemberCheckoutDiscount={handleMemberCheckoutDiscount} handleCompleteAccountSetup={handleCompleteAccountSetup} />
												</div>
											</div>
										</AccordionItem>
										<AccordionItem controlMode={'controlled'} isDisabled={paymentAccordian.isDisabled} isOpen={paymentAccordian.isOpen} onUserRequest={() => handleCheckoutStepsClose('payment')}>
											<h2 slot="accordionItemHeader">
												<AccordionItemHeader>{paymentAccordian.title}</AccordionItemHeader>
											</h2>
											<div>
												<div slot="accordionItemBody">
													<PaymentInformation config={props.config} 
														user={user} 
														setUser={setUser} 
														product={checkoutProduct} 
														setProduct={setCheckoutProduct} 
														handleTaxUpdateChange={handleTaxUpdateChange} 
														handleZipCodeRemoval={handleZipCodeRemoval} 
														handlePayment={handlePayment} 
														resetTaxForProduct={resetTaxForProduct} 
														handleUserAndProductUpdate={handleUserAndProductUpdate} 
														setModalHeader={setModalHeader} showModal={showModal} 
														setShowModal={setShowModal}
														setModalMessage={setModalMessage} 
														isLoading={isLoading}
														setIsLoading={setIsLoading}
														/>
												</div>
											</div>
										</AccordionItem>
									</Accordion>
								</div>
								<div className={`wk-column-4 wk-column-12-xs`}>
									{screenSize !== 'mobile' ? <OrderDetails checkoutProduct={checkoutProduct} handleAddOnProductPurchase={handleAddOnProductPurchase} />
										: <Smokebreak
											isOpen={openMobileOrderSmokeBreak}
											theme={'light'}
											position={'right'}
											isCloseButtonVisible={true}
											size={'wide'}
											srLabelledBy="smokebreak-id-001"
											hasStickyHeader={true}
											onUserRequest={() => setOpenMobileOrderSmokeBreak(!openMobileOrderSmokeBreak)}>
											<div
												className="smokebreak-pds-suggestion-body"
												slot="smokebreakBody"
											>
												<OrderDetails onCloseClick={() => setOpenMobileOrderSmokeBreak(!openMobileOrderSmokeBreak)} checkoutProduct={checkoutProduct} handleAddOnProductPurchase={handleAddOnProductPurchase} />
											</div>
										</Smokebreak>}
								</div>
							</>
						</div>
					</div>
				</>
			}

		</>
	);
};

export default Checkout;