import React, { useState } from 'react'

import DOMPurify from "isomorphic-dompurify";
import { ButtonField, ButtonGroup, ButtonGroupItem, CheckboxField, Modal, ModalBody, ModalFooter, ModalHeader } from '@ldi/components-react16';

const PaymentsLearnMoreContitions = (props: any) => {
    const pageUrl = props.config.assetsUrl + '/contents/learnMorePayments.html';
    const [htmlBody, setHtmlBody] = useState('');

    const { agreeTerms, setAgreeTerms, errors, openModal, isOpen, handleUserRequest, selectedOption } = props

    // read the contents from source and sanitize it
    React.useEffect(() => {
        fetch(pageUrl)
            .then(response => response.text())
            .then(text => {
                setHtmlBody(DOMPurify.sanitize(text));
            });
    }, []);// eslint-disable-line

    return (
        <><div className="wk-column-12 wk-column-12-xs">
            <p style={{fontSize:'1rem', lineHeight:'1.25'}}>
                {/* Review your order details and press the {selectedOption === 'credit'? 'Place Order' : selectedOption === 'applepay' ? 'Apple Pay' : selectedOption === 'gpay' ? 'GPay' : 'Place Order' } button to */}
                {/* finalize your purchase. */}
                Review your order before finalizing your purchase.
            </p>
            <p style={{fontSize:'1rem', lineHeight:'1.25'}}>
                If you are not completely satisfied with your OnDemand product(s), you may cancel within seven days and receive a prompt refund.
            </p>
            
        </div>
            <div className="wk-column-12 wk-column-12-xs display-flex flex-items-center">
                <div>
                    <CheckboxField label="I agree to the ">
                        <input name={`${selectedOption}-checkbox`} type="checkbox" checked={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)} />
                    </CheckboxField><span className="link-text" onClick={openModal} style={{verticalAlign:'text-bottom'}}>Terms and Conditions</span><span className="required-star" style={{verticalAlign:'text-bottom'}}>*</span>
                    {errors && errors.agreeTerms ?
                        <div className="text-danger">{errors.agreeTerms}</div>
                        :
                        <div>&nbsp;</div>
                            }
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                srLabelledBy={'modal-id-001'}
                srDescribedBy={'modal-id-002'}
                onUserRequest={(event: any) => handleUserRequest()}>
                <ModalHeader
                    titleId={'modal-id-001'}
                    onUserRequest={() => handleUserRequest()}>
                    Terms & Conditions
                </ModalHeader>
                <ModalBody descriptionId="modal-id-002">
                    <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup mode="static">
                        <ButtonGroupItem slot="buttonGroupItem">
                            <ButtonField>
                                <button
                                    type="button"
                                    id="cancel"
                                    onClick={() => handleUserRequest()}>
                                    Close
                                </button>
                            </ButtonField>
                        </ButtonGroupItem>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        </>
    )
}


export default PaymentsLearnMoreContitions;