import React, { useState } from 'react'
import '../assets/css/styles.css'
import '../assets/css/footer-pages-styles.css'

import DOMPurify from "isomorphic-dompurify";
import OrderDetails from './order-details';
import { Smokebreak } from '@ldi/components-react16';

const ThankYou = (props: any) => {
	const pageUrl = props.config.assetsUrl + '/contents/thankyou.html';
	const [htmlBody, setHtmlBody] = useState('');
	const [orderDetails, setOrderDetails] = useState<any>()
	const [checkoutProduct, setCheckoutProduct] = useState()
	const [openMobileOrderSmokeBreak, setOpenMobileOrderSmokeBreak] = useState(false);
	const [screenSize, setScreenSize] = useState('desktop')
	const [bannerURL, setBannerURL] = useState('')
	const [width, setWidth] = useState(0);

	// read the contents from source and sanitize it
	React.useEffect(() => {
		let orderDetails:any = {}
		const orderString = sessionStorage.getItem('orderDetails') ?? ""
		if (orderString) {
			orderDetails = JSON.parse(orderString)
			setOrderDetails(orderDetails)
		}

		const checkoutProductString = sessionStorage.getItem('checkoutProduct') ?? ""

		
		if (checkoutProductString) {
			const checkoutProduct = JSON.parse(checkoutProductString)
			checkoutProduct.addOnProducts = checkoutProduct.addOnProducts.filter(a => a.isPurchased);
			setCheckoutProduct(checkoutProduct)
			setBannerURL(Object.keys(checkoutProduct).length && Object.keys(checkoutProduct.product).length > 0 ? screenSize === "mobile" ? checkoutProduct.product.mobileBannerImageUrl : screenSize === "tablet" ? checkoutProduct.product.tabletBannerImageUrl : checkoutProduct.product?.bannerImageUrl : checkoutProduct.product?.bannerImageUrl)

		}

		fetch(pageUrl)
			.then(response => response.text())
			.then(text => {
				setHtmlBody(DOMPurify.sanitize(text.replace('##transactionId##', orderDetails?.transactionId)));
			});


		const handleResize = () => {
			const { innerWidth: width } = window;
        	setWidth(width);
			
			if (window.matchMedia("(max-width: 600px)").matches) {
				setScreenSize('mobile');
			} else if (window.matchMedia("(max-width: 1024px)").matches) {
				setScreenSize('tablet');
			} else {
				setScreenSize('desktop');
			}
		};

		
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);

	}, []);	//eslint-disable-line


	return (
		<>
			<div className="component rich-text tab-height article-text col-12 horizontal-content-center mrgn-0">
				<div className="component-content w-85vw">
				<div className='mrgn-btm'>
					<img src={bannerURL} className="banner-image" alt="society-banner-image" width={width > 1440 ? 1440 : width} style={{ objectFit: 'contain' }} ></img>
				</div>
					<div className="wk-grid-page-container">
					<div className="wk-grid-page-container p-0">
								<div className="display-block d-flex-mb" style={{backgroundColor:'white'}}>
									<div className="wk-column-7-xs wk-column-12" style={{marginLeft:'1rem'}}>
										
									</div>
									<div className="wk-column-12-xs display-flex flex-items-center flex-justify-end">
										<span className="d-none-lg link-text font-weight-400 pr-1 margin-top-2" onClick={() => setOpenMobileOrderSmokeBreak(!openMobileOrderSmokeBreak)}>Order details</span>
									</div>	
								</div>
							</div>
						<div className="wk-grid white-bg pb-em">
							{orderDetails && <div className="wk-column-8 wk-column-12-xs">
								<span className='thankyou-heading fw-500'>Thank you for using Medical Meetings OnDemand</span>
								<div dangerouslySetInnerHTML={{ __html: htmlBody }} />
							</div>
							}

							{checkoutProduct && <div className="wk-column-4 wk-column-12-xs">
								{/* <OrderDetails checkoutProduct={checkoutProduct} /> */}
								{screenSize !== 'mobile' ? <OrderDetails checkoutProduct={checkoutProduct} />
								: <Smokebreak
									isOpen={openMobileOrderSmokeBreak}
									theme={'light'}
									position={'right'}
									isCloseButtonVisible={true}
									size={'wide'}
									srLabelledBy="smokebreak-id-001"
									hasStickyHeader={true}
									onUserRequest={() => setOpenMobileOrderSmokeBreak(!openMobileOrderSmokeBreak)}>
									<div
										className="smokebreak-pds-suggestion-body"
										slot="smokebreakBody"
									>
										<OrderDetails onCloseClick={() => setOpenMobileOrderSmokeBreak(!openMobileOrderSmokeBreak)} checkoutProduct={checkoutProduct}/>
									</div>
								</Smokebreak>}
							</div>}
						</div>
					</div>
				</div>
			</div>

		</>
	)
}


export default ThankYou